import { ChainId, ChainInfo } from "@alch/dx-entities";
import ChainEmblem from "@components/ui/icons/ChainEmblem";
import { useMemo } from "react";
import ChainLabel from "./ChainLabel";

export interface ChainItem {
  value: ChainId;
  label: string;
  icon: React.ReactNode;
  displayLabel: React.ReactNode;
}

export default function useChainItems({
  chainInfos,
}: {
  chainInfos: ChainInfo[];
}): ChainItem[] {
  return useMemo(
    () =>
      chainInfos.map((chainInfo) => {
        return {
          value: chainInfo.id,
          label: chainInfo.name,
          icon: <ChainEmblem chainInfo={chainInfo} />,
          displayLabel: <ChainLabel chainInfo={chainInfo} />,
        };
      }),
    [chainInfos],
  );
}
