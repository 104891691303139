import { ChainId } from "@alch/dx-entities";
import {
  Bundler,
  Debugging,
  GasManager,
  NftApi,
  NodeApi,
  TokenApi,
  TraceApi,
  TransactionReceiptApi,
  TransactionSimulation,
  TransferApi,
  Webhooks,
} from "@alch/ui/assets/logos";
import { pickBy, uniq } from "lodash";
import { ComposerChainMethods } from "./methods";

interface ComposerCategory {
  label: string;
  value: string;
  iconUrl: string;
}

export const categoriesForChain = (
  chainMethods: ComposerChainMethods[ChainId],
) => {
  const categoryValues = uniq(
    Object.values(chainMethods || {}).map((method) => method.category),
  );

  return categories.filter((category) =>
    categoryValues.includes(category.value),
  );
};

export const methodsForCategories = (
  chainMethods: ComposerChainMethods[ChainId],
  categories: string[],
) => {
  if (categories.length === 0) return chainMethods;

  return pickBy(chainMethods, (method) => categories.includes(method.category));
};

const categories: ComposerCategory[] = [
  {
    value: "core",
    label: "Core",
    iconUrl: NodeApi,
  },
  {
    value: "nft",
    label: "NFT",
    iconUrl: NftApi,
  },
  {
    value: "token",
    label: "Token",
    iconUrl: TokenApi,
  },
  {
    value: "transfers",
    label: "Transfers",
    iconUrl: TransferApi,
  },
  {
    value: "transaction-receipts",
    label: "Transaction Receipts",
    iconUrl: TransactionReceiptApi,
  },
  {
    value: "trace",
    label: "Trace",
    iconUrl: TraceApi,
  },
  {
    value: "debug",
    label: "Debug",
    iconUrl: Debugging,
  },
  {
    value: "gas-manager",
    label: "Gas Manager",
    iconUrl: GasManager,
  },
  {
    value: "bundler",
    label: "Bundler",
    iconUrl: Bundler,
  },
  {
    value: "transaction-simulation",
    label: "Transaction Simulation",
    iconUrl: TransactionSimulation,
  },
  {
    value: "gas-optimized-transactions",
    label: "Gas Optimized Transactions",
    iconUrl: GasManager,
  },
  {
    value: "Notify",
    label: "notify",
    iconUrl: Webhooks,
  },
];

export default categories;
