export enum TestId {
  AccessKeysPage = "access-keys-page",
  TeamPage = "team-page",
  ProfilePage = "profile-page",
  AlertsPage = "alerts-page",
  AccountKitPage = "account-kit-page",
  BillingPage = "billing-page",
  AppsPage = "apps-page",
  BundlerApiPage = "bundler-api-page",
  ChainsPage = "chains-page",
  DebugApiPage = "debug-api-page",
  EmbeddedAccountsPage = "embedded-accounts-page",
  GasManagerApiPage = "gas-manager-api-page",
  GasManagerPage = "gas-manager-page",
  HomePage = "home-page",
  MempoolPage = "mempool-page",
  NftApiPage = "nft-api-page",
  NodeApiPage = "node-api-page",
  RequestLogsPage = "request-logs-page",
  RollupsPage = "rollups-page",
  SandboxPage = "sandbox-page",
  ServicesPage = "services-page",
  SubgraphsPage = "subgraphs-page",
  TokenApiPage = "token-api-page",
  TraceApiPage = "trace-api-page",
  TransactionReceiptsApiPage = "transaction-receipts-api-page",
  TransactionSimulationApiPage = "transaction-simulation-api-page",
  TransfersApiPage = "transfers-api-page",
  UsagePage = "usage-page",
  UserOperationsApiPage = "user-operations-api-page",
  WebhooksPage = "webhooks-page",
  WebsocketsPage = "smart-websockets-page",
}
