import { QueryClientProvider } from "@tanstack/react-query";
import { createBrowserHistory } from "history";
import { Provider as ReduxProvider } from "react-redux";
import { Router } from "react-router-dom";
import { createStore } from "redux";

import { initAnalytics } from "@util/analytics";
import { queryClient } from "../../http/query";
import Composer from "../composer";

import ErrorBoundary from "../../components/app/ErrorBoundary";
import ComposerStandaloneLayout from "./ComposerStandaloneLayout";

import { WebappTrpc } from "@util/trpc/trpcClient";
import "../../index.css";

initAnalytics();

const baseUrl = window.location.origin;

const reduxStore = createStore(() => null);

const ComposerStandaloneRoot = () => {
  const history = createBrowserHistory();

  return (
    <ReduxProvider store={reduxStore}>
      <Router history={history}>
        <WebappTrpc.trpc.Provider
          client={WebappTrpc.trpcClient}
          queryClient={queryClient}
        >
          <QueryClientProvider client={queryClient}>
            <ComposerStandaloneLayout>
              <ErrorBoundary>
                <Composer baseUrl={baseUrl} isPublic />
              </ErrorBoundary>
            </ComposerStandaloneLayout>
          </QueryClientProvider>
        </WebappTrpc.trpc.Provider>
      </Router>
    </ReduxProvider>
  );
};

export default ComposerStandaloneRoot;
