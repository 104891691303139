import { Button } from "@alch/ui";
import { ArrowUpRightSm, File05 } from "@alch/ui/icons/16";
import classNames from "classnames";
import { Control, useWatch } from "react-hook-form";

import { AlchemyDocsUrl } from "@util/urls";

import ComposerParamInput from "./ComposerParamInput";
import { buildName } from "./ComposerParamInput/lib";
import { ComposerFormState } from "./lib/form";
import { ComposerChainMethods } from "./lib/methods";

interface ComposerParamsProps extends React.HTMLAttributes<HTMLDivElement> {
  control: Control<ComposerFormState>;
  loading: boolean;
  methods: ComposerChainMethods;
}

const ComposerParams = ({
  control,
  loading,
  methods,
  className,
  ...props
}: ComposerParamsProps) => {
  const selectedChain = useWatch({ control, name: "chain" });
  const selectedMethodName = useWatch({ control, name: "method" });

  const selectedMethod = methods[selectedChain]?.[selectedMethodName];

  if (!selectedMethod) return null;

  const isJsonRPCMethod =
    selectedMethod.requestBody?.type === "object" &&
    selectedMethod.requestBody?.properties["jsonrpc"]?.default === "2.0";
  const jsonRPCparams =
    isJsonRPCMethod && selectedMethod.requestBody?.type === "object"
      ? selectedMethod.requestBody.properties["params"]
      : null;
  const requestBody = isJsonRPCMethod
    ? jsonRPCparams
    : selectedMethod.requestBody;

  return (
    <div {...props} className={classNames(className, "flex flex-col gap-8")}>
      {!requestBody && !selectedMethod.queryParams?.length ? (
        <span className="mt-2 text-paragraph-300-regular text-grayscale-700">
          This method has no parameters you can configure
        </span>
      ) : null}

      {selectedMethod.queryParams?.map((param, index) => {
        return (
          <ComposerParamInput
            key={index}
            control={control as unknown as Control}
            name={buildName("query", param.name)}
            param={param}
          />
        );
      })}

      {requestBody ? (
        <ComposerParamInput
          control={control as unknown as Control}
          name={isJsonRPCMethod ? "body.params" : "body"}
          param={requestBody}
        />
      ) : null}

      <div className="mt-auto flex flex-wrap gap-2">
        <Button
          as="a"
          href={selectedMethod.docsUrl || AlchemyDocsUrl.Home}
          target="_blank"
          intent="tertiary"
          leadingIcon={<File05 />}
          trailingIcon={<ArrowUpRightSm />}
        >
          View docs
        </Button>

        <Button className="ml-auto" type="submit" loading={loading}>
          Send request
        </Button>
      </div>
    </div>
  );
};

export default ComposerParams;
