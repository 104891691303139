import { ChainInfo, NetworkId, NetworkInfo } from "@alch/dx-entities";
import { mapValues } from "lodash";
import type { ComposerChainMethods, ComposerMethod } from "./methods";

export type RawSchema = Record<
  string,
  Record<string, Omit<ComposerMethod, "networks"> & { networks: string[] }>
>;

const parseNetworkId = ({
  schemaNetwork,
  chainInfo,
  networkInfos,
}: {
  schemaNetwork: string;
  chainInfo: ChainInfo;
  networkInfos: NetworkInfo[];
}): NetworkId | undefined => {
  const networkSubdomain = `${chainInfo.id === "MATIC" ? "polygon" : chainInfo.id.toLowerCase()}-${
    chainInfo.id === "BASE" && schemaNetwork === "testnet"
      ? "goerli"
      : schemaNetwork
  }`;

  return networkInfos.find((info) => info.kebabCaseId === networkSubdomain)?.id;
};

export const parseSchema = ({
  rawSchema,
  chainInfos,
  networkInfos,
  singleCategory,
}: {
  rawSchema: RawSchema;
  chainInfos: ChainInfo[];
  networkInfos: NetworkInfo[];
  singleCategory?: string;
}): ComposerChainMethods => {
  return Object.fromEntries(
    Object.entries(rawSchema).flatMap(([schemaChain, schemaChainMethods]) => {
      const chainInfo = chainInfos.find(
        (info) => info.id.toLowerCase() === schemaChain,
      );

      if (chainInfo == null) return [];

      let chainMethods = mapValues(schemaChainMethods, (schemaMethod) => {
        return {
          ...schemaMethod,
          networks: schemaMethod.networks
            .map((network) =>
              parseNetworkId({
                schemaNetwork: network,
                chainInfo,
                networkInfos,
              }),
            )
            .filter((network): network is NetworkId => network != null),
        };
      });

      if (singleCategory) {
        chainMethods = Object.fromEntries(
          Object.entries(chainMethods).filter(([_key, method]) => {
            return method.category === singleCategory;
          }),
        );
      }

      return [[chainInfo.id, chainMethods]];
    }),
  );
};
