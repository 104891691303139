import classNames from "classnames";

// eslint-disable-next-line import/no-cycle -- recursive import is intentional
import ComposerParamInput, { type ComposerParamComponentProps } from ".";
import ComposerParamLabel from "./ComposerParamLabel";
import { buildName, getNestedIndexClassName } from "./lib";

interface ComposerObjectParamProps
  extends ComposerParamComponentProps<"object"> {
  nestedIndex?: number;
}

const ComposerObjectParam = ({
  control,
  name,
  param,
  nestedIndex = 0,
}: ComposerObjectParamProps) => {
  const shouldNest = !!param.name;

  const params = (
    <div className="flex flex-col gap-4">
      {Object.entries(param.properties).map(([key, property]) => (
        <ComposerParamInput
          key={key}
          control={control}
          name={buildName(name, key)}
          param={property}
          nestedIndex={shouldNest ? nestedIndex + 1 : nestedIndex}
        />
      ))}
    </div>
  );

  return (
    <div>
      <ComposerParamLabel param={param} />

      {shouldNest ? (
        <div
          className={classNames(
            "rounded-sm p-2",
            getNestedIndexClassName(nestedIndex),
          )}
        >
          {params}
        </div>
      ) : (
        params
      )}
    </div>
  );
};

export default ComposerObjectParam;
