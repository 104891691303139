// used on SignUp app, SignupShareView.tsx
export const REINFORCED_TRANSACTIONS_COST_PER_APP = 999;

export const COMPOSER_API_KEY = "xZF7o-Vl3z94HOqwaQtrZP06swu4_E15";
export const TRACE_COMPOSER_API_KEY = "dAnEgTX45tYXUXfNwtnXGRpJ-YiDBL7d";

export const DEFAULT_LOCALE = "en-US";

export enum SignupSource {
  TWITTER = 1,
  TEAMMATE = 2,
  SEARCH_ENGINE = 3,
  TUTORIAL = 4,
  OTHER = 999,
}

export enum OnDemandActionType {
  UNSPECIFIED = 0,
  ALERT = 1,
  STOPPAGE = 2,
}

export enum OnDemandThresholdUnit {
  UNSPECIFIED = 0,
  CU = 1,
  DOLLAR = 2,
}

export enum LocalStorageKeys {
  AppListSort = "appListSort",
  AppSortOrder = "appSortOrder",
}
