import { ChainId } from "@alch/dx-entities";
import SelectField from "@components/ui/select-field/SelectField";
import { Control, FieldPathByValue, FieldValues } from "react-hook-form";
import BaseChainSelect, {
  createSelectedValueRenderer,
} from "./BaseChainSelect";
import useChainItems from "./useChainItems";

interface ChainSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, ChainId>,
  MultiSelect extends boolean,
> extends React.ComponentProps<typeof BaseChainSelect<MultiSelect>> {
  control: Control<TFieldValues>;
  name: TName;
}

function ChainSelect<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, ChainId>,
  MultiSelect extends boolean = false,
>({
  control,
  name,
  chainInfos,
  ...props
}: ChainSelectProps<TFieldValues, TName, MultiSelect>) {
  const items = useChainItems({ chainInfos });

  return (
    <SelectField
      control={control}
      name={name}
      items={items}
      renderSelectedValue={createSelectedValueRenderer(chainInfos)}
      {...props}
    />
  );
}

export default ChainSelect;
