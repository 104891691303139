import { Button, IconButton } from "@alch/ui";
import { Plus02, Trash01 } from "@alch/ui/icons/16";
import classNames from "classnames";
import { range } from "lodash";
import { useController, useWatch } from "react-hook-form";

import {
  ComposerParamValueByType,
  PRIMITIVE_PARAMS,
  buildParamDefaultValue,
} from "../lib/params";
// eslint-disable-next-line import/no-cycle -- recursive import is intentional
import ComposerParamInput, { type ComposerParamComponentProps } from ".";
import ComposerParamLabel from "./ComposerParamLabel";
import { buildName, getNestedIndexClassName } from "./lib";

interface ComposerArrayParamProps extends ComposerParamComponentProps<"array"> {
  nestedIndex?: number;
}

const ComposerArrayParam = ({
  control,
  name,
  param,
  nestedIndex = 0,
}: ComposerArrayParamProps) => {
  const { field } = useController({ control, name });
  const value = (useWatch({ control, name }) || []) as NonNullable<
    ComposerParamValueByType<"array">
  >;

  const minItems = param.min || 0;
  const itemsShown = Math.max(minItems, value?.length || 0);
  const maxItems = param.max || Infinity;

  const isPrimitiveItem = PRIMITIVE_PARAMS.includes(param.items.type);

  return (
    <div className="flex flex-col gap-1">
      <ComposerParamLabel param={param} />

      <div
        key={itemsShown}
        className={classNames(
          "flex flex-col rounded-sm p-2",
          isPrimitiveItem ? "gap-2" : "gap-4",
          getNestedIndexClassName(nestedIndex),
        )}
      >
        {range(itemsShown).map((index) => (
          <div key={index} className={classNames("flex items-start gap-2")}>
            <div className="flex-1">
              <ComposerParamInput
                control={control}
                name={buildName(name, index)}
                param={param.items}
                nestedIndex={nestedIndex + 1}
              />
            </div>

            {itemsShown > minItems && (
              <IconButton
                className={classNames(
                  "text-grayscale-700",
                  isPrimitiveItem ? "mt-1.5" : "mt-4",
                )}
                aria-label="Remove item"
                rounded="full"
                background="dark"
                padding="lg"
                onClick={() => {
                  field.onChange([
                    ...value.slice(0, index),
                    ...value.slice(index + 1),
                  ]);
                }}
              >
                <Trash01 />
              </IconButton>
            )}
          </div>
        ))}

        {itemsShown < maxItems && (
          <Button
            leadingIcon={<Plus02 />}
            intent="tertiary"
            size="sm"
            className="h-9 justify-start"
            disabled={itemsShown >= maxItems}
            onClick={() => {
              field.onChange([
                ...(value || []),
                buildParamDefaultValue(param.items),
              ]);
            }}
          >
            Add
          </Button>
        )}
      </div>
    </div>
  );
};

export default ComposerArrayParam;
