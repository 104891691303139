import { Button, Tooltip, useCopyToClipboard } from "@alch/ui";
import { LinkAngled } from "@alch/ui/icons/16";
import { useEffect } from "react";
import { Control, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { analyticsEvent, EventPrefix } from "@util/analytics";

import { NetworkInfo } from "@alch/dx-entities";
import { ComposerFormState } from "./lib/form";
import { buildShareUrl } from "./lib/sharing";

interface ComposerShareButtonProps {
  control: Control<ComposerFormState>;
  baseUrl: string;
  networkInfos: NetworkInfo[];
}

const ComposerShareButton = ({
  control,
  baseUrl,
  networkInfos,
}: ComposerShareButtonProps) => {
  const { isCopiedToClipboard, handleCopyToClipboard } = useCopyToClipboard();
  const history = useHistory();

  const formState = useWatch({ control }) as ComposerFormState;
  const shareUrl = buildShareUrl({ baseUrl, state: formState, networkInfos });

  const generateShortShareUrl = () => {
    analyticsEvent(`${EventPrefix.Sandbox}: Copied share url`);

    handleCopyToClipboard(shareUrl);
  };

  useEffect(() => {
    history.replace({ search: shareUrl.split("?")[1] });
  }, [shareUrl, history]);

  return (
    <Tooltip content="Copied" withArrow side="top" open={isCopiedToClipboard}>
      <Button
        size="sm"
        leadingIcon={<LinkAngled />}
        intent="tertiary"
        onClick={generateShortShareUrl}
      >
        Share request
      </Button>
    </Tooltip>
  );
};

export default ComposerShareButton;
