import { ChainId, ChainInfo } from "@alch/dx-entities";
import { SelectField, SelectFieldProps } from "@alch/ui";
import ChainLabel from "./ChainLabel";
import useChainItems from "./useChainItems";

export const createSelectedValueRenderer = (chainInfos: ChainInfo[]) => {
  const chainValueToInfo = Object.fromEntries(
    chainInfos.map((info) => [info.id, info]),
  ) as Record<ChainId, ChainInfo>;

  const ChainSelectedValue = (val: ChainId | ChainId[]) => {
    if (Array.isArray(val)) {
      if (val.length === 1) {
        const chainInfo = chainValueToInfo[val[0]];

        return <ChainLabel chainInfo={chainInfo} />;
      }

      if (val.length === 0) {
        return <span>All chains</span>;
      }

      return <span>{val.length} chains</span>;
    }

    const chainInfo = chainValueToInfo[val];

    if (!chainInfo) {
      return null;
    }

    return <ChainLabel chainInfo={chainInfo} />;
  };

  return ChainSelectedValue;
};

interface BaseChainSelectProps<Multiselect extends boolean>
  extends Omit<SelectFieldProps<ChainId, Multiselect>, "items"> {
  chainInfos: ChainInfo[];
}

const BaseChainSelect = <Multiselect extends boolean = false>({
  chainInfos,
  ...props
}: BaseChainSelectProps<Multiselect>) => {
  const items = useChainItems({ chainInfos });

  return (
    <SelectField
      items={items}
      renderSelectedValue={createSelectedValueRenderer(chainInfos)}
      {...props}
    />
  );
};

export default BaseChainSelect;
