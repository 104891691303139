import classNames from "classnames";
import { ReactNode } from "react";

export type PageHeaderProps = {
  title: React.ReactNode;
  subtitle?: string | ReactNode;
  trailingElements?: ReactNode[];
  className?: string;
};

function PageHeader({
  title,
  subtitle,
  trailingElements,
  className,
}: PageHeaderProps) {
  return (
    <div className="pb-6">
      <div className="flex items-center gap-2">
        <h2
          className={classNames(
            "text-heading-h2 text-primary md:text-heading-h1",
            className,
          )}
        >
          {title}
        </h2>

        <div className="flex flex-1 justify-end gap-2">
          {trailingElements?.map((element) => element)}
        </div>
      </div>

      {subtitle && (
        <div
          className={classNames(
            "mt-3 text-paragraph-300-regular text-secondary",
          )}
        >
          {subtitle}
        </div>
      )}
    </div>
  );
}

export default PageHeader;
