import { Radio } from "@alch/ui";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";

import {
  buildParamDefaultValue,
  buildParamValueSchema,
  sortOneOfParams,
} from "../lib/params";
// eslint-disable-next-line import/no-cycle -- recursive import is intentional
import ComposerParamInput, { type ComposerParamComponentProps } from ".";
import ComposerParamLabel from "./ComposerParamLabel";
import { getNestedIndexClassName } from "./lib";

interface ComposerOneOfParamProps
  extends ComposerParamComponentProps<"oneOf" | "anyOf"> {
  arrayIndex?: number;
  nestedIndex?: number;
}

const ComposerOneOfParam = ({
  control,
  name,
  param,
  nestedIndex = 0,
}: ComposerOneOfParamProps) => {
  const { resetField } = useFormContext();
  const { field } = useController({
    control,
    name,
  });
  const [activeOptionIndex, setActiveOptionIndex] = useState(0);

  const handleChangeActiveOption = useCallback(
    (stringIndex: string) => {
      const index = +stringIndex;
      const newParamOption = param.items[index];

      setActiveOptionIndex(index);
      resetField(name, {
        defaultValue: buildParamDefaultValue(newParamOption),
      });
    },
    [resetField, name, param.items],
  );

  const activeParamOption = param.items[activeOptionIndex];

  useEffect(() => {
    const activeItem = sortOneOfParams(param.items).find(
      (item) =>
        buildParamValueSchema(item, { strict: false }).safeParse(field.value)
          .success,
    );

    setActiveOptionIndex(
      Math.max(0, activeItem ? param.items.indexOf(activeItem) : 0),
    );
  }, [field.value, param.items]);

  if (!activeParamOption) return null;

  return (
    <div>
      <ComposerParamLabel param={param} />

      <Radio.Group
        value={String(activeOptionIndex)}
        onValueChange={handleChangeActiveOption}
        className="mb-2 flex flex-wrap gap-5"
      >
        {param.items.map((item, index) => (
          <Radio.Item key={index} size="sm" value={String(index)}>
            {item.name || item.type}
          </Radio.Item>
        ))}
      </Radio.Group>

      <div
        className={classNames(
          "rounded-sm p-2",
          getNestedIndexClassName(nestedIndex),
        )}
      >
        <ComposerParamInput
          key={activeOptionIndex}
          control={control}
          name={name}
          param={activeParamOption}
          nestedIndex={nestedIndex + 1}
        />
      </div>
    </div>
  );
};

export default ComposerOneOfParam;
