import { CodeBox } from "@alch/ui";
import { useCallback } from "react";
import {
  Control,
  UseFormHandleSubmit,
  useFormState,
  useWatch,
} from "react-hook-form";

import { NetworkInfo } from "@alch/dx-entities";

import ComposerParams from "./ComposerParams";
import ComposerRequestPreview from "./ComposerRequestPreview";
import ComposerShareButton from "./ComposerShareButton";
import { ComposerFormState } from "./lib/form";
import { ComposerChainMethods } from "./lib/methods";
import useMakeComposerRequest from "./lib/useMakeComposerRequest";

interface ComposerRequestProps extends React.HTMLAttributes<HTMLDivElement> {
  control: Control<ComposerFormState>;
  handleSubmit: UseFormHandleSubmit<ComposerFormState>;
  baseUrl: string;
  methods: ComposerChainMethods;
  networkInfos: NetworkInfo[];
}

const ComposerRequest = ({
  control,
  handleSubmit,
  baseUrl,
  methods,
  networkInfos,
  ...props
}: ComposerRequestProps) => {
  const selectedMethodName = useWatch({ control, name: "method" });
  const methodFormState = useFormState({ control, name: "method" });
  const chain = useWatch({ control, name: "chain" });
  const network = useWatch({ control, name: "network" });

  // Form state will reset when changing method.
  // State will be out of sync for one render when changing method manually.
  const isChangingMethod =
    methodFormState.defaultValues?.method !== selectedMethodName;

  const method = methods[chain]?.[selectedMethodName];
  const networkInfo =
    networkInfos.find((info) => info.id === network) || networkInfos[0];

  const makeComposerRequest = useMakeComposerRequest({ method, networkInfo });

  const urlNetwork = method?.url.replace("{network}", networkInfo.kebabCaseId);

  const handleFormSubmit = useCallback(
    (state: ComposerFormState) => {
      makeComposerRequest.mutate(state);
    },
    [makeComposerRequest],
  );

  if (!method || isChangingMethod) {
    return null;
  }

  const responseSizeInMbs = (makeComposerRequest.data?.length || 0) / 10e5;
  const responseIsTooLargeToShow = responseSizeInMbs > 3; // an arbitrary number
  const responseSizeInMbsFormatted = responseSizeInMbs.toLocaleString("us-en", {
    maximumFractionDigits: 0,
  });

  return (
    <div {...props}>
      <div className="mb-6 flex flex-wrap items-center gap-2">
        <h3 className="mr-auto font-mono text-heading-h3 font-medium break-all">
          {selectedMethodName}
        </h3>

        <ComposerShareButton
          control={control}
          baseUrl={baseUrl}
          networkInfos={networkInfos}
        />
      </div>

      <form
        className="grid gap-6 lg:grid-cols-9"
        onSubmit={handleSubmit(handleFormSubmit, console.warn)}
      >
        <ComposerParams
          className="col-span-1 rounded-lg border border-grayscale-200 p-4 lg:col-span-6"
          control={control}
          loading={makeComposerRequest.isPending}
          methods={methods}
        />

        <ComposerRequestPreview
          className="col-span-1 lg:col-span-3"
          control={control}
          method={method}
          url={urlNetwork ?? ""}
        />
      </form>

      {makeComposerRequest.isError ? (
        <CodeBox
          className="mt-4 min-w-0"
          title="Error"
          snippets={[
            {
              language: "json",
              code: makeComposerRequest.error?.message || "Unknown error",
            },
          ]}
        />
      ) : makeComposerRequest.data ? (
        <CodeBox
          className="mt-4 min-w-0"
          title="Response"
          snippets={[
            {
              language: responseIsTooLargeToShow ? "text" : "json",
              code: responseIsTooLargeToShow
                ? `Response is too large to show (${responseSizeInMbsFormatted} MB)`
                : makeComposerRequest.data,
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default ComposerRequest;
