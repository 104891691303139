import classNames from "classnames";

// eslint-disable-next-line import/no-cycle -- recursive import is intentional
import ComposerParamInput, { type ComposerParamComponentProps } from ".";
import ComposerParamLabel from "./ComposerParamLabel";
import { buildName, getNestedIndexClassName } from "./lib";

interface ComposerTupleParamProps extends ComposerParamComponentProps<"tuple"> {
  nestedIndex?: number;
}

const ComposerArrayParam = ({
  control,
  name,
  param,
  nestedIndex = 0,
}: ComposerTupleParamProps) => {
  return (
    <div className="flex flex-col gap-1">
      <ComposerParamLabel param={param} />

      <div
        className={classNames(
          "flex flex-col gap-4 rounded-sm p-2",
          getNestedIndexClassName(nestedIndex),
        )}
      >
        {param.items.map((param, index) => (
          <div key={index}>
            <ComposerParamInput
              control={control}
              name={buildName(name, index)}
              param={param}
              nestedIndex={nestedIndex + 1}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComposerArrayParam;
