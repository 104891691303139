import { ChainInfo, NetworkId, NetworkInfo } from "@alch/dx-entities";
import sortBy from "lodash/sortBy";
import { useMemo } from "react";
import NetworkItem from "./NetworkItem";

export enum NetworkSort {
  Name = "name",
  Order = "order",
}

export default function useNetworkItems({
  networkInfos,
  chainInfo,
  disabledNetworks = [],
  sort,
}: {
  networkInfos: NetworkInfo[];
  chainInfo?: ChainInfo;
  disabledNetworks?: NetworkId[];
  sort?: NetworkSort;
}) {
  return useMemo(
    () =>
      sortBy(
        Object.values(networkInfos),
        (n) => {
          // Move disabled networks to the end
          return disabledNetworks.includes(n.id) ? 1 : 0;
        },
        (n) => {
          switch (sort) {
            case NetworkSort.Name:
              return n.name;
            case NetworkSort.Order:
            default:
              // Keep the order as is
              return 0;
          }
        },
      ).map((networkInfo) => {
        const disabled = disabledNetworks.includes(networkInfo.id);

        return {
          disabled,
          value: networkInfo.id,
          label: networkInfo.name,
          displayLabel: (
            <NetworkItem
              networkInfo={networkInfo}
              chainInfo={chainInfo}
              disabled={disabled}
            />
          ),
        };
      }),
    [networkInfos, chainInfo, disabledNetworks, sort],
  );
}
