import { ChainInfo, NetworkInfo } from "@alch/dx-entities";
import { Tooltip } from "@alch/ui";
import { AlertTriangle } from "@alch/ui/icons/16";
import NetworkEmblem from "@components/ui/icons/NetworkEmblem";
import { ViewAsPreservingLink } from "@features/common/ViewAsPreservingLink";
import networkNameWithoutChain from "@util/networkNameWithoutChain";
import { pathBuilder } from "@util/paths";
import clsx from "clsx";

interface NetworkItemProps {
  networkInfo: NetworkInfo;
  chainInfo?: ChainInfo;
  appId?: string;
  disabled?: boolean;
}

const NetworkItem = ({
  networkInfo,
  chainInfo,
  appId,
  disabled = false,
}: NetworkItemProps) => {
  const showDisabledIcon = !!appId;
  const disabledNetworkTooltip = appId ? (
    <div className="text-center text-paragraph-100-regular">
      This network is disabled for this app.
      <span>
        {" "}
        You can change the permission in{" "}
        <ViewAsPreservingLink
          to={pathBuilder.appDetails(appId, "networks")}
          className="underline hover:no-underline"
          onClick={(e) => e.stopPropagation()}
        >
          Networks
        </ViewAsPreservingLink>
      </span>
    </div>
  ) : (
    "Not available for this tool"
  );

  // Show the network name without chain name if optional chainInfo is provided
  const networkName = chainInfo
    ? networkNameWithoutChain({ networkInfo, chainInfo })
    : networkInfo.name;

  return (
    <div className="flex items-center gap-2">
      <Tooltip
        content={disabledNetworkTooltip}
        side="right"
        withArrow
        disabled={!disabled && !showDisabledIcon}
      >
        <div
          className={clsx(
            "flex items-center gap-2",
            disabled ? "opacity-50" : undefined,
          )}
        >
          <NetworkEmblem networkInfo={networkInfo} className="size-5" />
          <span className="text-black">{networkName}</span>
        </div>
      </Tooltip>

      {disabled && showDisabledIcon && (
        <Tooltip content={disabledNetworkTooltip}>
          <AlertTriangle className="text-red-600" />
        </Tooltip>
      )}
    </div>
  );
};

export default NetworkItem;
