import { ChainInfo, NetworkId, NetworkInfo } from "@alch/dx-entities";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffectOnUpdate } from "@util/hooks";
import { useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { methodsForCategories } from "./lib/categories";
import {
  composerFormSchemaForMethod,
  defaultValuesForMethod,
} from "./lib/form";
import { ComposerChainMethods } from "./lib/methods";
import { parseStateFromShareUrl } from "./lib/sharing";

const useComposerForm = ({
  methods,
  search,
  networkInfos,
  chainInfos,
  singleCategory,
}: {
  methods: ComposerChainMethods;
  search: string;
  networkInfos: NetworkInfo[];
  chainInfos: ChainInfo[];
  singleCategory?: string;
}) => {
  const shareState = parseStateFromShareUrl({
    methods,
    search,
    networkInfos,
    chainInfos,
  });

  const defaultNetwork = shareState ? shareState.network : "ETH_MAINNET";
  const defaultNetworkInfo =
    networkInfos.find((info) => info.id === defaultNetwork) || networkInfos[0];
  const defaultChain = defaultNetworkInfo.chainId;
  const bestMethod =
    singleCategory && methods[defaultChain]
      ? Object.keys(methods[defaultChain])[0]
      : "eth_getBlockByNumber";
  const defaultMethodName = shareState ? shareState.method : bestMethod;
  const defaultMethod = methods[defaultChain]?.[defaultMethodName];
  const defaultValues = Object.assign(
    {},
    defaultValuesForMethod({
      methods,
      networkInfo: defaultNetworkInfo,
      methodName: defaultMethodName,
    }),
    singleCategory ? { categories: [singleCategory] } : {},
    shareState,
  );

  const [schema, setSchema] = useState(() =>
    composerFormSchemaForMethod({
      method: defaultMethod,
      networkInfos,
      chainInfos,
    }),
  );

  const form = useForm({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onBlur",
  });

  const chain = useWatch({ control: form.control, name: "chain" });
  const network = useWatch({ control: form.control, name: "network" });
  const categories = useWatch({ control: form.control, name: "categories" });
  const method = useWatch({ control: form.control, name: "method" });

  const selectedCategories = useMemo(
    () => (singleCategory ? [singleCategory] : categories),
    [singleCategory, categories],
  );

  const resetForm = (networkOverwrite: NetworkId) => {
    const chainMethod = methods[chain]?.[method];
    const networkInfo =
      networkInfos.find((n) => n.id === networkOverwrite) || networkInfos[0];
    form.reset({
      ...defaultValuesForMethod({ methods, networkInfo, methodName: method }),
      categories: selectedCategories,
    });
    setSchema(
      composerFormSchemaForMethod({
        method: chainMethod,
        networkInfos,
        chainInfos,
      }),
    );
  };

  useEffectOnUpdate(() => {
    const chainDefaultNetworkInfo =
      networkInfos.find((info) => info.chainId === chain) ?? networkInfos[0];
    const chainMethods = methods[chain];

    if (chainMethods?.[method]) {
      // We need to reset form state even if the method is the same as schema definitions might vary between chains
      resetForm(chainDefaultNetworkInfo.id);
    } else {
      form.setValue("network", chainDefaultNetworkInfo.id);
      form.setValue("categories", singleCategory ? [singleCategory] : []);
      form.setValue("method", Object.keys(chainMethods || {})[0]);
    }
  }, [chain, singleCategory]);

  useEffectOnUpdate(() => {
    const categoryMethods = methodsForCategories(
      methods[chain],
      selectedCategories,
    );

    if (!categoryMethods?.[method]) {
      form.setValue("method", Object.keys(categoryMethods || {})[0]);
    }
  }, [selectedCategories]);

  useEffectOnUpdate(() => {
    resetForm(network);
  }, [method]);

  return form;
};

export default useComposerForm;
