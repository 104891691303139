import { ChainInfo } from "@alch/dx-entities";
import ChainEmblem from "@components/ui/icons/ChainEmblem";

interface ChainLabelProps {
  chainInfo: ChainInfo;
}

function ChainLabel({ chainInfo }: ChainLabelProps) {
  return (
    <div className="flex min-w-0 items-center gap-2">
      <div className={"flex items-center gap-2"}>
        <ChainEmblem chainInfo={chainInfo} className="size-6" />
        <span className="truncate">{chainInfo.name}</span>
      </div>
    </div>
  );
}

export default ChainLabel;
