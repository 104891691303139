import { ChainInfo, NetworkInfo } from "@alch/dx-entities";
import { FormProvider } from "react-hook-form";
import ComposerMethod from "./ComposerMethod";
import ComposerRequest from "./ComposerRequest";
import { ComposerChainMethods } from "./lib/methods";
import useComposerForm from "./useComposerForm";

interface ComposerFormProps {
  methods: ComposerChainMethods;
  chainInfos: ChainInfo[];
  networkInfos: NetworkInfo[];
  baseUrl: string;
  singleCategory?: string;
  isPublic?: boolean;
}

const ComposerForm = ({
  methods,
  baseUrl,
  chainInfos,
  networkInfos,
  singleCategory,
  isPublic,
}: ComposerFormProps) => {
  const form = useComposerForm({
    methods,
    search: window.location.search,
    chainInfos,
    networkInfos,
    singleCategory,
  });

  return (
    <FormProvider {...form}>
      <div className="flex flex-wrap items-start gap-6 text-grayscale-950">
        <ComposerMethod
          className="flex-1 basis-[260px] overflow-hidden rounded-xl border border-grayscale-200 bg-white"
          control={form.control}
          methods={methods}
          chainInfos={chainInfos}
          networkInfos={networkInfos}
          singleCategory={singleCategory}
          isPublic={isPublic}
        />

        <ComposerRequest
          className="min-w-0 flex-grow-5 basis-[720px] rounded-xl border border-grayscale-200 bg-white p-6"
          control={form.control}
          handleSubmit={form.handleSubmit}
          baseUrl={baseUrl}
          methods={methods}
          networkInfos={networkInfos}
        />
      </div>
    </FormProvider>
  );
};

export default ComposerForm;
