import { NetworkId } from "@alch/dx-entities";
import React from "react";
import { Control, FieldPathByValue, FieldValues } from "react-hook-form";
import { SelectField } from "../../components/ui";
import BaseNetworkSelect from "./BaseNetworkSelect";
import SelectedNetworks from "./SelectedNetworks";
import useNetworkItems from "./useNetworkItems";

interface NetworkSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, NetworkId>,
  MultiSelect extends boolean,
> extends React.ComponentProps<typeof BaseNetworkSelect<MultiSelect>> {
  control: Control<TFieldValues>;
  name: TName;
}

function NetworkSelect<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, NetworkId>,
  MultiSelect extends boolean = false,
>({
  control,
  name,
  networkInfos,
  disabledNetworks,
  chainInfo,
  sort,
  RenderComponent = SelectedNetworks,
  placeholder = "All networks",
  ...props
}: NetworkSelectProps<TFieldValues, TName, MultiSelect>) {
  const items = useNetworkItems({
    networkInfos,
    chainInfo,
    disabledNetworks,
    sort,
  });

  return (
    <SelectField
      control={control}
      name={name}
      items={items}
      placeholder={placeholder}
      renderSelectedValue={(values) => (
        <RenderComponent
          values={values}
          networkInfos={networkInfos}
          chainInfo={chainInfo}
          placeholder={placeholder}
        />
      )}
      {...props}
    />
  );
}

export default NetworkSelect;
