import { Period } from "@alch/dx-entities";
import { SelectField } from "@components/ui";
import useCurrentUser from "@queries/useCurrentUser";
import { trpc } from "@util/trpc/trpcClient";
import orderBy from "lodash/orderBy";
import { useEffect, useMemo } from "react";
import {
  Control,
  FieldPathByValue,
  FieldValues,
  useController,
} from "react-hook-form";
import { useLocalStorage } from "usehooks-ts";

interface ComposerAppProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>,
> {
  control: Control<TFieldValues>;
  name: TName;
}

const ComposerApp = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>,
>({
  control,
  name,
}: ComposerAppProps<TFieldValues, TName>) => {
  const currentUser = useCurrentUser();
  const apps = trpc.apps.getApps.useQuery();
  const appRequestCounts = trpc.requests.getRequestStatusCountsByApp.useQuery({
    period: Period.Hour_24,
  });

  const sortedApps = useMemo(() => {
    return orderBy(
      apps.data ?? [],
      (app) => appRequestCounts.data?.[app.id]?.total ?? 0,
      "desc",
    );
  }, [apps.data, appRequestCounts.data]);

  const selectItems = useMemo(
    () => sortedApps.map((app) => ({ label: app.name, value: app.authToken })),
    [sortedApps],
  );

  const {
    field: { onChange: handleSetApiKey },
  } = useController({ control, name });

  const [savedApiKey, saveApiKey] = useLocalStorage<string | null>(
    `composerApiKey:${currentUser.data?.id || ""}`,
    null,
  );

  useEffect(() => {
    if (!apps.data || !savedApiKey) return;
    if (apps.data.some((app) => app.authToken === savedApiKey)) return;

    saveApiKey(null);
  }, [apps.data, savedApiKey, saveApiKey]);

  useEffect(() => {
    if (savedApiKey) {
      handleSetApiKey(savedApiKey);
    }
  }, [savedApiKey, handleSetApiKey]);

  return (
    <SelectField
      className="mb-8"
      control={control}
      name={name}
      selectAllItem={{ label: "Sandbox Demo App", value: null }}
      onSelect={saveApiKey}
      items={selectItems}
      label="Select an app"
    />
  );
};

export default ComposerApp;
